@font-face {
  font-family: Namu;
  src: url("../public/fonts/NAMU-Pro.eot");
  src: url("../public/fonts/NAMU-Pro.eot") format("embedded-opentype"),
    url("../public/fonts/NAMU-Pro.woff2") format("woff2"), url("../public/fonts/NAMU-Pro.woff") format("woff"),
    url("../public/fonts/NAMU-Pro.ttf") format("truetype");
  /* url('src/fonts/NAMU-Pro.svg#besom_2regular') format('svg'); */
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  font-family: Namu;
  font-weight: 300;
  font-size: 12px;
}

p {
  font-family: Namu;
  /* font-weight: 300;
    font-size: 12px; */
}

.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

/* HEADER */

a {
  color: black;
}
